<template>
  <v-form
    id="addEmployeeForm"
    ref="formAddEmployee"
    v-model="valid"
    lazy-validation
    autocomplete="off"
    @submit.prevent=""
  >
    <v-container class="py-0">
      <v-row class="mb-5">
        <v-col cols="12">
          <h2 class="align-self-end page-title">
            {{ $t(`settings.title.${mode}`) }}
          </h2>
        </v-col>
        <v-col v-if="authEsa">
          <span>{{ orgName }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-select
            id="position"
            v-model="employee.role"
            item-text="label"
            item-value="value"
            :items="options.positions"
            :label="$i18n.t('label.position')"
            :rules="rules.position"
            :disabled="pageMode === 'edit' || loading"
            @change="employee.storeId = null"
            required
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="7" md="5" lg="4">
          <v-select
            id="store"
            v-model="employee.storeId"
            item-text="storeName"
            item-value="storeId"
            :items="options.stores"
            :label="$i18n.t('label.store')"
            :rules="!roleOrg ? rules.store : []"
            :disabled="roleOrg || pageMode === 'edit' || loading"
            :required="!roleOrg"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="7" md="5" lg="4">
          <v-text-field
            id="fio"
            v-model="employee.fio"
            :label="$i18n.t('label.fio')"
            :rules="rules.fio"
            :disabled="loading"
            maxlength="100"
            color="privat"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-text-field
            id="phone"
            v-model="employee.phoneNumber"
            :label="$i18n.t('label.phone')"
            :rules="rules.phone"
            :disabled="loading"
            maxlength="13"
            color="privat"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-text-field
            id="inn"
            v-model="employee.inn"
            :label="$i18n.t('label.inn')"
            :rules="rules.inn"
            :disabled="loading"
            maxlength="10"
            color="privat"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="7"
          md="5"
          lg="4"
          class="d-flex justify-space-between flex-column flex-sm-row"
        >
          <v-btn
            id="cancel"
            class="mb-4 mb-sm-0"
            color="blue-grey lighten-5"
            elevation="1"
            :disabled="loading"
            @click.native="$router.go(-1)"
          >
            {{ $t("action.cancel") }}
          </v-btn>
          <v-btn
            id="addEmployee"
            color="privat"
            elevation="1"
            :disabled="loading"
            :loading="loading"
            @click.native="sendEmployee"
          >
            <span class="white--text" v-if="pageMode !== 'edit'">
              {{ $t("action.add") }}
            </span>
            <span class="white--text" v-else>
              {{ $t("action.save") }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import CONST from "@/utils/constants";
import { mapGetters } from "vuex";

export default {
  name: "SettingsEmployee",
  props: {
    options: Object,
    user: Object,
    company: Object,
    loading: Boolean
  },
  data() {
    return {
      valid: true,
      employee: {
        role: null,
        storeId: null,
        fio: "",
        phoneNumber: "+380",
        inn: ""
      },
      mode: "employee/add",
      staff: []
    };
  },
  computed: {
    ...mapGetters(["getStaff", "getPointOfEntry"]),
    rules() {
      return {
        position: [v => !!v || this.$i18n.t("rules.required")],
        store: [v => !!v || this.$i18n.t("rules.required")],
        fio: [
          v => !!v || this.$i18n.t("rules.required"),
          v =>
            CONST.MIN_MAX_FIO.test(v) ||
            this.$i18n.t("rules.fio.min_max_correct")
        ],
        phone: [
          v => !!v || this.$i18n.t("rules.required"),
          v =>
            CONST.REG_EXP_PHONE.test(v) || this.$i18n.t("rules.phone.correct")
        ],
        inn: [
          v => !!v || this.$i18n.t("rules.required"),
          v => /^[0-9]{10}$/.test(v) || this.$i18n.t("rules.inn.correct")
        ]
      };
    },
    pageMode() {
      return this.$route.params.id;
    },
    editUserId() {
      return this.$route.query.id;
    },
    roleOrg() {
      return this.employee.role === "HEAD" || this.employee.role === "SUB_HEAD";
    },
    authEsa() {
      return this.getPointOfEntry;
    },
    orgName() {
      let orgName = this.$i18n.t("settings.title.manageCompany");

      if (this.authEsa) {
        orgName = this.company?.name || this.selectedCompany?.name;
      }

      return orgName;
    },
    selectedCompany() {
      return this.options.companies.find(
        el => el.id === +this.$route.query.orgId
      );
    }
  },
  methods: {
    sendEmployee() {
      if (this.$refs.formAddEmployee.validate()) {
        if (this.authEsa) {
          this.employee.orgId = +this.$route.query.orgId;
        }
        if (this.pageMode !== "edit") {
          this.$emit("event", ["addEmployee", this.employee]);
        } else {
          this.$emit("event", ["updateEmployee", this.employee]);
        }
      }
    },
    setEmployeeAfterReload() {
      if (!this.authEsa) {
        this.setEmployee();
      } else {
        this.setEmployeeEsa();
      }
    },
    async setEmployee() {
      if (!this.user) {
        await this.$store
          .dispatch("getEmployees", this)
          .then(() => {
            this.staff = this.getStaff;
          })
          .catch(() => {
            this.$router.go(-1);
          });

        let user = this.staff.filter(
          el => el.employeeId === +this.editUserId
        )[0];

        this.setUser(user);
      } else {
        this.setUser(this.user);
      }
    },
    async setEmployeeEsa() {
      let orgId = this.$route.query.orgId;

      if (!this.user) {
        await this.$store
          .dispatch("getStaffCompany", { orgId: orgId, self: this })
          .then(() => {
            this.staff = this.getStaff;
          })
          .catch(() => {
            this.$router.go(-1);
          });

        let user = this.staff.filter(
          el => el.employeeId === +this.editUserId
        )[0];

        this.setUser(user);
      } else {
        this.setUser(this.user);
      }
    },
    setUser(user) {
      this.employee = {
        role: user.role.value,
        storeId: user.storeId,
        fio: user.fio,
        phoneNumber: user.phoneNumber,
        inn: user.inn,
        id: user?.employeeId || this.editUserId
      };
    }
  },
  mounted() {
    if (this.pageMode === "edit") {
      this.mode = "employee/edit";
      this.setEmployeeAfterReload();
    }
    if (this.authEsa) {
      this.$emit("event", ["getEsaDefaultSettings", false]);
    }
  }
};
</script>

<style scoped lang="scss">
#addEmployeeForm {
  width: 100%;
  .container {
    max-width: 100%;
  }
  .v-btn {
    height: 39px;
  }
  [class*="col"] {
    padding: 2px 12px;
  }
}
#cancel {
  width: 37%;
  @media (max-width: 600px) {
    width: 100%;
  }
}
#addEmployee {
  width: 57%;
  @media (max-width: 600px) {
    width: 100%;
  }
}
</style>
